import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-955e614e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-details-content-readonly" }
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { class: "columns is-multiline py-6 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionDetailsTop = _resolveComponent("CollectionDetailsTop")!
  const _component_TitleYearLabel = _resolveComponent("TitleYearLabel")!
  const _component_ArtworkCard = _resolveComponent("ArtworkCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CollectionDetailsTop, {
      onEdit: _ctx.onEdit,
      collection: _ctx.collection
    }, null, 8, ["onEdit", "collection"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "column",
            key: column
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedResults[column - 1], (item) => {
              return (_openBlock(), _createBlock(_component_ArtworkCard, {
                key: item.artwork['id'],
                imgSrc: item.artwork.reproductions.length ? item.artwork.reproductions[0] : null,
                artworkId: item.artwork['id'],
                fullHeight: true
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_TitleYearLabel, {
                    artwork: item.artwork
                  }, null, 8, ["artwork"])
                ]),
                creators: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getCreatorsNamesById(item.artwork.id)), 1)
                ]),
                _: 2
              }, 1032, ["imgSrc", "artworkId"]))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}