import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19612828"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kunsten-textarea-debounce" }
const _hoisted_2 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          size: "xs",
          class: "mr-4"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      "data-testid-textarea": "",
      spellcheck: "false",
      rows: "4",
      ref: "textDebounce",
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTextDebounce($event.target.value)))
    }, null, 40, _hoisted_2)
  ]))
}