
import { useColumnView, UserCollectionArtworkDto, UserCollectionDto, useUserCollections } from "@kunsten/core";
import { defineComponent, onMounted, PropType, ref } from "vue";
import { Container, Draggable } from "vue3-smooth-dnd";

import ArtworkCard from "../../../shared/components/ArtworkCard.vue";
import CollectionBlock from "../../../shared/components/CollectionBlock.vue";
import IconText from "../../../shared/components/IconText.vue";
import TitleYearLabel from "../../../shared/components/TitleYearLabel.vue";
import CollectionEditTop from "./CollectionEditTop.vue";

export default defineComponent({
    name: "CollectionDetailsContentEditable",
    components: { ArtworkCard, CollectionBlock, IconText, CollectionEditTop, Container, Draggable, TitleYearLabel },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        creatorsNames: {
            type: Array as PropType<{ id: string; names: string[] }[]>,
            default: () => [],
        },
    },
    setup(props) {
        const isChangeOrder = ref(false);
        const orderError = ref(false);
        const loading = ref(false);

        const { getGroupedResults, addElement, removeElement, getUngroupedResults, isCorrect } =
            useColumnView<UserCollectionArtworkDto>();

        const collectionDisplay = ref({ ...props.collection });
        const groupedResults = ref(getGroupedResults(props.columns, collectionDisplay.value.artworks));

        const { postRemoveCollectionArtwork, postMultipleCollectArtwork, patchUserCollection } = useUserCollections();

        const getCreatorsNamesById = (id: string) => {
            if (!props.creatorsNames?.length) return "";
            const names = props.creatorsNames.find((c) => c.id === id)?.names;
            return names ? names.join(", ") : "";
        };

        // first element is preview of collection
        // so we need to apply offset to artworks
        const getColumnOffsetIndex = (index: number) => {
            return index - 1 < 0 ? props.columns - 1 : index - 1;
        };

        const findIndexAndColumn = (artworkId: string) => {
            let column = -1;
            let removedIndex = -1;

            for (let i = 0; i < props.columns; i++) {
                const index = groupedResults.value[i]
                    .map((c: UserCollectionArtworkDto) => c.artwork["@id"])
                    .indexOf(artworkId);
                if (index !== -1) {
                    removedIndex = index;
                    column = i;
                    break;
                }
            }

            return { column, removedIndex };
        };

        const updateCollection = (data: Partial<{ public: boolean }>) => {
            patchUserCollection(props.collection.id, data).then(
                () => {
                    loading.value = false;
                    onPublicChange(false);
                },
                (err) => {
                    console.log(err);
                    loading.value = false;
                }
            );
        };

        const onDelete = (collectionId: string, artworkId: string) => {
            postRemoveCollectionArtwork({
                userCollection: collectionId,
                artwork: artworkId,
            }).then(
                () => {
                    const { column, removedIndex } = findIndexAndColumn(artworkId);

                    if (column >= 0 && removedIndex >= 0) {
                        groupedResults.value = removeElement(column, removedIndex, groupedResults.value);
                        collectionDisplay.value.artworks = getUngroupedResults(props.columns, groupedResults.value);
                        if (collectionDisplay.value.artworks.length === 0) {
                            loading.value = true;
                            updateCollection({ public: false });
                        }
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
        };

        const saveOrder = () => {
            orderError.value = !isCorrect(props.columns, groupedResults.value);
            if (!orderError.value) {
                loading.value = true;
                const artworks = getUngroupedResults(props.columns, groupedResults.value).map((a, i) => {
                    return {
                        artwork: a.artwork["@id"],
                        userCollection: a.collection,
                        sortNumber: i,
                    };
                });
                postMultipleCollectArtwork(artworks).then(
                    () => {
                        isChangeOrder.value = !isChangeOrder.value;
                        loading.value = false;
                    },
                    () => {
                        loading.value = false;
                        isChangeOrder.value = !isChangeOrder.value;
                    }
                );
            }
        };

        const onPublicChange = (value: boolean) => {
            collectionDisplay.value.public = value;
        };

        const onDrop = (
            index: number,
            dropResult: { removedIndex: number; addedIndex: number; payload: UserCollectionArtworkDto }
        ) => {
            const column = getColumnOffsetIndex(index);
            const removedIndex = dropResult.removedIndex;
            const addedIndex = dropResult.addedIndex;
            const payload = dropResult.payload;
            if (typeof removedIndex === "number") {
                groupedResults.value = removeElement(column, removedIndex, groupedResults.value);
                collectionDisplay.value.artworks = getUngroupedResults(props.columns, groupedResults.value);
            }
            if (typeof addedIndex === "number") {
                groupedResults.value = addElement(payload, column, addedIndex, groupedResults.value);
                collectionDisplay.value.artworks = getUngroupedResults(props.columns, groupedResults.value);
            }
        };

        const getArtworkPayload = (column: number) => {
            return (index: number) => {
                return groupedResults.value[column][index];
            };
        };

        onMounted(() => {
            setTimeout(() => {
                isChangeOrder.value = true;
            }, 250);
        });

        return {
            loading,
            orderError,
            isChangeOrder,
            groupedResults,
            collectionDisplay,
            onDrop,
            onDelete,
            saveOrder,
            onPublicChange,
            getArtworkPayload,
            getColumnOffsetIndex,
            getCreatorsNamesById,
        };
    },
});
