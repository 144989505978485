
import { DefineComponent, defineComponent } from "vue";
import { debounce } from "lodash-es";
import Spinner from "./Spinner.vue";

export default defineComponent( {
    name: "TextDebounce",
    emits: ["update:modelValue"],
    components: { Spinner },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: false,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.focus) (this.$refs.textDebounce as HTMLTextAreaElement)?.focus();
    },
    methods: {
        onTextDebounce: debounce(function (this: DefineComponent, text: string) {
            this.$emit("update:modelValue", text);
        }, 750),
    },
});
