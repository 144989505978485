
import { UserCollectionDto, useUserCollections, useArtworks, useAPI, useDocumentTitle } from "@kunsten/core";
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { AxiosResponse } from "axios";
import { useI18n } from "vue-i18n";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import CollectionDetailsContentReadonly from "../shared/CollectionDetailsContentReadonly.vue";
import CollectionDetailsContentEditable from "../shared/CollectionDetailsContentEditable.vue";

export default defineComponent({
    name: "CollectionDetails",
    components: {
        SpinnerContainer,
        CollectionDetailsContentEditable,
        CollectionDetailsContentReadonly,
    },
    setup() {
        const columns = ref(4);
        const route = useRoute();
        const loading = ref(true);
        const { locale, t } = useI18n();
        const collection = ref({} as UserCollectionDto);
        const isEdit = ref(false);
        const creatorsNames = ref([] as { id: string; names: string[] }[]);
        const { getEsArtworks } = useArtworks();
        const { getUserCollection } = useUserCollections();
        const { userData } = useAPI();
        const { setTitle } = useDocumentTitle(t("general.base-title"));

        const collectionId = computed(() => {
            return route.params.id as string;
        });

        const fetchCreatorsNames = (ids: string[]) => {
            if (!ids.length) return;
            getEsArtworks({ lang: locale.value, id: ids }).then((res) => {
                const artworks = res.data["hydra:member"];
                if (artworks.length) {
                    creatorsNames.value = artworks.map((a) => {
                        return {
                            id: a.id,
                            names: a.creatorsNames,
                        };
                    });
                }
            });
        };

        const getArtworkIds = (collection: UserCollectionDto) => {
            if (!collection) return [];
            if (!collection.artworks) return [];
            return collection.artworks.map((a) => a.artwork.id);
        };

        const fetchCollection = () => {
            if (!collectionId.value) return;
            loading.value = true;
            getUserCollection(collectionId.value).then(
                (res: AxiosResponse<UserCollectionDto>) => {
                    loading.value = false;
                    collection.value = res.data;
                    if (collection.value.title) {
                        setTitle(collection.value.title);
                    } else {
                        setTitle();
                    }
                    fetchCreatorsNames(getArtworkIds(res.data));
                },
                (err: Error) => {
                    loading.value = false;
                    console.log(err);
                }
            );
        };

        const isOwnedByUser = computed(() => {
            if (!userData.value?.id || !collection.value?.owner) return false;
            return `/api/users/${userData.value?.id}` === collection.value?.owner;
        });

        watch(
            collectionId,
            () => {
                fetchCollection();
            },
            { immediate: true }
        );

        watch(
            route,
            (route) => {
                if (isOwnedByUser.value) {
                    isEdit.value = Boolean(route.query.edit) || false;
                }
                // refresh collection after editing
                if (!isEdit.value) {
                    fetchCollection();
                }
            },
            { deep: true, immediate: true }
        );

        return { loading, collection, creatorsNames, columns, isEdit };
    },
});
