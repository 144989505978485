
import { useColumnView, UserCollectionArtworkDto, UserCollectionDto } from "@kunsten/core";
import { defineComponent, PropType, computed } from "vue";
import { useRouter } from "vue-router";
import ArtworkCard from "../../../shared/components/ArtworkCard.vue";
import TitleYearLabel from "../../../shared/components/TitleYearLabel.vue";
import CollectionDetailsTop from "./CollectionDetailsTop.vue";

export default defineComponent({
    name: "CollectionDetailsContentReadonly",
    components: { ArtworkCard, CollectionDetailsTop, TitleYearLabel },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        creatorsNames: {
            type: Array as PropType<{ id: string; names: string[] }[]>,
            default: () => [],
        },
    },
    setup(props) {
        const router = useRouter();
        const { getGroupedResults } = useColumnView<UserCollectionArtworkDto>();

        const getCreatorsNamesById = (id: string) => {
            if (!props.creatorsNames?.length) return "";
            const names = props.creatorsNames.find((c) => c.id === id)?.names;
            return names ? names.join(", ") : "";
        };

        const onEdit = () => {
            router.push({ name: "CollectionDetails", query: { edit: "true" } });
        };

        const groupedResults = computed(() => {
            return getGroupedResults(props.columns, props.collection.artworks);
        });

        return { groupedResults, getCreatorsNamesById, onEdit };
    },
});
