import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19e16998"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-edit-top mt-4" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "warning-message mb-3 has-text-danger"
}
const _hoisted_5 = {
  key: 0,
  class: "fas fa-toggle-on"
}
const _hoisted_6 = {
  key: 1,
  class: "fas fa-toggle-off"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputDebounce = _resolveComponent("InputDebounce")!
  const _component_TextDebounce = _resolveComponent("TextDebounce")!
  const _component_IconText = _resolveComponent("IconText")!
  const _component_CollectionsTop = _resolveComponent("CollectionsTop")!

  return (_openBlock(), _createBlock(_component_CollectionsTop, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputDebounce, {
            modelValue: _ctx.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
            placeholder: _ctx.$t('collection.enter-collection-name'),
            focus: true,
            loading: _ctx.loadingName
          }, null, 8, ["modelValue", "placeholder", "loading"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TextDebounce, {
            modelValue: _ctx.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputDescription($event))),
            placeholder: _ctx.$t('collection.enter-collection-description')
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        (_ctx.warningMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.warningMessage), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateVisibility())),
          class: "btn py-2 px-0 pr-3"
        }, [
          _createVNode(_component_IconText, {
            size: "md",
            loading: _ctx.loading
          }, {
            icon: _withCtx(() => [
              (_ctx.isPublic)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : (_openBlock(), _createElementBlock("i", _hoisted_6))
            ]),
            text: _withCtx(() => [
              (_ctx.isPublic)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("collection.this-collection-is-public")), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t("collection.this-collection-is-private")), 1))
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ])
    ]),
    _: 1
  }))
}