
import { defineComponent, PropType, ref } from "vue";
import CollectionsTop from "../shared/CollectionsTop.vue";
import IconText from "../../../shared/components/IconText.vue";
import { UserCollectionDto, useUserCollections, IsOwnedByUser } from "@kunsten/core";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "CollectionDetailsTop",
    components: { CollectionsTop, IconText, IsOwnedByUser },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const title = ref(props.collection.title);
        const router = useRouter();

        const { deleteUserCollection } = useUserCollections();

        const onEdit = () => {
            emit("edit");
        };

        const onDelete = () => {
            loading.value = true;
            deleteUserCollection(props.collection.id).then(
                () => {
                    loading.value = false;
                    router.push({ name: "MyCollections" });
                },
                (err) => {
                    console.log(err);
                }
            );
        };

        return { title, onDelete, onEdit, loading };
    },
});
