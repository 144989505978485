import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-794d9c24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-details" }
const _hoisted_2 = {
  key: 0,
  class: "collection-details-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionDetailsContentReadonly = _resolveComponent("CollectionDetailsContentReadonly")!
  const _component_CollectionDetailsContentEditable = _resolveComponent("CollectionDetailsContentEditable")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.isEdit)
            ? (_openBlock(), _createBlock(_component_CollectionDetailsContentReadonly, {
                key: 0,
                columns: _ctx.columns,
                creatorsNames: _ctx.creatorsNames,
                collection: _ctx.collection
              }, null, 8, ["columns", "creatorsNames", "collection"]))
            : (_openBlock(), _createBlock(_component_CollectionDetailsContentEditable, {
                key: 1,
                columns: _ctx.columns,
                creatorsNames: _ctx.creatorsNames,
                collection: _ctx.collection
              }, null, 8, ["columns", "creatorsNames", "collection"]))
        ]))
      : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
  ]))
}