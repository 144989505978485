
import { defineComponent, PropType, ref } from "vue";
import CollectionsTop from "../shared/CollectionsTop.vue";
import IconText from "../../../shared/components/IconText.vue";
import InputDebounce from "../../../shared/components/InputDebounce.vue";
import { UserCollectionDto, useUserCollections } from "@kunsten/core";
import TextDebounce from "@/shared/components/TextDebounce.vue";

export default defineComponent({
    name: "CollectionEditTop",
    components: {TextDebounce, CollectionsTop, IconText, InputDebounce },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const artWorks = ref(props.collection.artworks);
        const isPublic = ref(props.collection.public);
        const title = ref(props.collection.title);
        const description = ref(props.collection.description || "");
        const loading = ref(false);
        const loadingName = ref(false);
        const { patchUserCollection } = useUserCollections();
        const warningMessage = ref("");

        const onInput = (text: string) => {
            updateTitle(text);
        };

        const onInputDescription = (text: string) => {
            updateDescription(text);
        };

        const updateCollection = (data: Partial<{ public: boolean; title: string; description: string }>) => {
            patchUserCollection(props.collection.id, data).then(
                () => {
                    loading.value = false;
                    loadingName.value = false;
                    if (!data.title && !data.description) emit("publicChange", data.public);
                },
                (err) => {
                    console.log(err);
                    loading.value = false;
                    loadingName.value = false;
                }
            );
        };

        const updateTitle = (text: string) => {
            if (text) {
                loadingName.value = true;
                title.value = text;
                updateCollection({ title: text });
            }
        };

        const updateDescription = (text: string) => {
            description.value = text;
            updateCollection({ description: text });
        };

        const updateVisibility = () => {
            if (!artWorks.value || artWorks.value.length === 0) {
                warningMessage.value = 'Please add artworks to the collection before making it public.';
                return;
            }
            isPublic.value = !isPublic.value;
            loading.value = true;
            updateCollection({ public: isPublic.value });
        };
        return { updateTitle, updateDescription, updateVisibility, onInput, onInputDescription, loading, isPublic, title, description, loadingName, warningMessage };
    },
});
